<template>
    <v-container v-resize="onResize" fluid grid-list-md class="padless">
        <v-layout wrap class="hidden-sm-and-down">
            <v-flex d-flex xs12 justify-center align-center class="page-top-margin">
                &nbsp;
            </v-flex>
        </v-layout>
        
        <div id="g-map" :style="{ height: mapHeight + 'px', width: '100%' }"/>

        <v-layout id="lists" row wrap :style="{ padding: + '0px', backgroundColor: 'white' }">
            <v-flex v-for="list in neighborhoodList" :key="list.header" style="background-color:white;">
                <v-list flat dense>
                    <v-subheader class="primary--text text--darken-1 list-header">{{ list.header }}</v-subheader>
                    <v-list-item-group color="primary">
                        <v-list-item v-for="item in list.list" :key="item.id" style="min-height: 30px; padding-left: 10px;" @click="listItemClick(item)">
                        <v-list-item-content>
                            <v-list-item-title v-html="item.title" style="font-weight: 400;"></v-list-item-title>
                        </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import gmapsInit from "@/utils/gmaps";
import { DEFAULTS } from "@/constants/Defaults";
import { IMAGES } from "@/constants/Images";

export default {
  name: "Neighborhood",

  data() {
    return {
      mapHeight: 0,
      listsTop: 0,
      google: null,
      map: null,
      openedInfoWindow: null,
      markers: []
    };
  },

  computed: {
    ...mapGetters(["property", "neighborhoodList"])
  },

  methods: {
    ...mapActions(["getNeighborhoodList"]),
    onResize() {
      let windowSize = { x: window.innerWidth, y: window.innerHeight };
      let tabsHeight = document.getElementById("navigationTabs").clientHeight;

      this.mapHeight = windowSize.y - tabsHeight - 60;
      this.listsTop = this.mapHeight - 20;
    },
    listItemClick(item) {
      try {
        if (!item.address.lat || !item.address.lng) {
          return;
        }

        for (var i = 0; i < this.markers.length; i++) {
          if (
            parseFloat(this.markers[i].getPosition().lat()) ===
              parseFloat(item.address.lat) &&
            parseFloat(this.markers[i].getPosition().lng()) ===
              parseFloat(item.address.lng) &&
            this.markers[i].title === item.title
          ) {
            this.clearMarkers();
            this.markers.splice(i, 1);
            this.showMarkers();

            return;
          }
        }

        const bounds = new this.google.maps.LatLngBounds();
        this.markers.forEach(marker => {
          bounds.extend(marker.position);
        });

        const location = {
          coordinates: {
            lat: parseFloat(item.address.lat),
            lng: parseFloat(item.address.lng)
          },
          title: item.title,
          info: this.getInfoWindowContent(
            item.title,
            item.address.street,
            item.address.city,
            item.address.state,
            item.address.postalCode,
            item.phone,
            item.webSite
          )
        };

        bounds.extend(location.coordinates);

        const marker = this.addMarker(location);

        this.map.fitBounds(bounds);
        this.showInfoWindow(marker, location.info);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }

      this.scrollToTop();
    },
    addMarker(location) {
      var marker = new this.google.maps.Marker({
        position: location.coordinates,
        map: this.map,
        title: location.title,
        animation: this.google.maps.Animation.DROP
      });

      if (location.iconImage) {
        marker.setIcon(location.iconImage);
      }

      this.markers.push(marker);

      this.google.maps.event.addListener(marker, "click", () => {
        this.showInfoWindow(marker, location.info);
      });

      return marker;
    },
    setMapOnAll(map) {
      for (var i = 0; i < this.markers.length; i++) {
        this.markers[i].setMap(map);
      }
    },
    clearMarkers() {
      if (this.openedInfoWindow) this.openedInfoWindow.close();
      this.setMapOnAll(null);
    },
    showMarkers() {
      this.setMapOnAll(this.map);
    },
    showInfoWindow(marker, content) {
      if (this.openedInfoWindow) this.openedInfoWindow.close();

      const infoWindow = new this.google.maps.InfoWindow({
        content: content,
        maxWidth: 220
      });

      infoWindow.open(this.map, marker);
      this.openedInfoWindow = infoWindow;
    },
    getInfoWindowContent(
      name,
      street,
      city,
      state,
      postalCode,
      phone,
      webSite
    ) {
      let content = "<h3>" + name + "</h3>";

      if (street && city && state && postalCode) {
        content +=
          "<div>" +
          street +
          "<br>" +
          city +
          ", " +
          state +
          " " +
          postalCode +
          "</div>";
      }

      if (phone) {
        content +=
          "<div style='height: 5px;'>&nbsp;</div><div><a href='tel:+1" +
          phone.replace(/[^0-9]/g, "") +
          "'>" +
          phone +
          "</a></div>";
      }

      if (webSite) {
        content +=
          "<div style='height: 5px;'>&nbsp;</div><div><a href='" +
          webSite +
          "' target='new'>" +
          webSite +
          "</a></div>";
      }

      return content;
    },
    openWebSite(webSite) {
      // eslint-disable-next-line no-console
      console.log("webSite", webSite);
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    }
  },

  created() {
    this.getNeighborhoodList();
  },

  async mounted() {
    try {
      this.google = await gmapsInit();

      let location = {};

      if (
        !this.property ||
        !this.property.address ||
        !this.property.address.lat ||
        !this.property.address.lng
      ) {
        location = {
          lat: DEFAULTS.lat,
          lng: DEFAULTS.lng
        };
      } else {
        location = {
          lat: this.property.address.lat,
          lng: this.property.address.lng
        };
      }

      const options = {
        zoom: 13,
        center: location,
        gestureHandling: "cooperative"
      };

      this.map = new this.google.maps.Map(
        document.getElementById("g-map"),
        options
      );

      const locations = [
        {
          coordinates: location,
          iconImage: IMAGES.mapGreenDot,
          title: this.property.name,
          info: this.getInfoWindowContent(
            this.property.name,
            this.property.address.street,
            this.property.address.city,
            this.property.address.state,
            this.property.address.postalCode,
            this.property.phone,
            this.property.webSite
          )
        }
      ];

      const bounds = new this.google.maps.LatLngBounds();

      locations.forEach(location => {
        if (!location.coordinates) {
          return;
        }

        bounds.extend(location.coordinates);

        this.addMarker(location);

        if (locations.length > 1) {
          this.map.fitBounds(bounds);
        }
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }
};
</script>

<style scoped>
.g-map {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: calc(100vh - 205px);
}

.g-map-sm {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: calc(100vh - 230px);
}
</style>
